<template>
  <v-card class="overflow-hidden" elevation="0">
    <v-app-bar absolute color="white" dark scroll-target="#scrolling-techniques-3">
      <v-btn to="/" icon>
        <v-icon color="black darken-2">mdi-arrow-left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn icon @click="dial(phone)">
        <v-icon color="blue">mdi-phone</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs v-model="activeTab" grow next-icon="mdi-arrow-right-bold-box-outline blue"
          prev-icon="mdi-arrow-left-bold-box-outline blue" show-arrows>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#tbrush" class="black--text"> TOILET BRUSH </v-tab>
          <v-tab href="#brm" class="black--text"> BROOMS </v-tab>
          <v-tab href="#brsh" class="black--text"> BRUSH </v-tab>
          <v-tab href="#bmop" class="black--text"> BUCKET MOPS </v-tab>
          <v-tab href="#dbin" class="black--text"> DUSTBIN </v-tab>
          <v-tab href="#mops" class="black--text"> MOPS </v-tab>
          <v-tab href="#scrbr" class="black--text"> SCRUBBER </v-tab>
          <v-tab href="#wiper" class="black--text"> WIPERS </v-tab>
          <v-tab href="#access" class="black--text"> ACCESSORIES </v-tab>

        </v-tabs>
      </template>
    </v-app-bar>

    <v-sheet id="scrolling-techniques-3" class="overflow-y-auto" max-height="820">
      <v-tabs-items v-model="activeTab" class="mt-15">
        <v-tab-item value="tbrush">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/tbrush.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in cleaningtoilet" :key="i" :cols="item.flex">
                <v-card color="#E0F2F1" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="brm">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/broom.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in cleaningbroom" :key="i" :cols="item.flex">
                <v-card color="#E0F2F1" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="brsh">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/brush.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in cleaningbrush" :key="i" :cols="item.flex">
                <v-card color="#E0F2F1" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="bmop">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/bucmop.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in cleaningbucmop" :key="i" :cols="item.flex">
                <v-card color="#E0F2F1" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="dbin">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/dustbin.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in cleaningdustbin" :key="i" :cols="item.flex">
                <v-card color="#E0F2F1" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="mops">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/mops.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in cleaningmops" :key="i" :cols="item.flex">
                <v-card color="#E0F2F1" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="scrbr">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/scrubbers.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in cleaningscrub" :key="i" :cols="item.flex">
                <v-card color="#E0F2F1" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="wiper">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/wipers.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in cleaningwiper" :key="i" :cols="item.flex">
                <v-card color="#E0F2F1" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="access">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/access.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in cleaningacccess" :key="i" :cols="item.flex">
                <v-card color="#E0F2F1" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-card>
</template>

<script>
import productData from "./JsonData/product.json";

export default {
  props: ["product"],
  data: () => ({
    props: ["product"],
    phone: 8022235726,
    dialog: false,
    show: false,
    activeTab: null,
    dialogdata: {
      id: "",
      color: "",
      src: "",
      title: "",
      itemname: "",
      partno: "",
      flex: "",
      mrp: "",
      packstd: "",
      colorrange: "",
      ptmtitems: [],
    },
    cleaning: [],
    cleaningtoilet: productData.cleaningtoilet,
    cleaningacccess: productData.cleaningacccess,
    cleaningbroom: productData.cleaningbroom,
    cleaningbrush: productData.cleaningbrush,
    cleaningbucmop: productData.cleaningbucmop,
    cleaningdustbin: productData.cleaningdustbin,
    cleaningmops: productData.cleaningmops,
    cleaningscrub: productData.cleaningscrub,
    cleaningwiper: productData.cleaningwiper,
  }),
  beforeMount() {
    this.activeTab = this.product || "tbrush";
  },
  methods: {
    onClickOutside() {
      this.dialog = false;
    },

    setVal(cleaning) {
      console.log(cleaning);
      this.dialogdata.title = cleaning.title;
      this.dialogdata.src = cleaning.src;
      this.dialogdata.itemname = cleaning.itemname;
      this.dialogdata.partno = cleaning.partno;
      this.dialogdata.mrp = cleaning.mrp;
      this.dialogdata.packstd = cleaning.packstd;
      this.dialogdata.colorrange = cleaning.colorrange;
    },
    goTodetail(proId) {
      this.$router.push({
        name: "cleaningSol_details",
        params: { Pid: proId, product: this.activeTab },
      });
    },
    dial: function (number) {
      window.location = "tel:" + number;
    },
    getstoreItem() {
      // this.favitems = JSON.parse(localStorage.getItem("favitems"));
      // console.log(this.favitems);
      if (localStorage.getItem("klensitems"))
        this.klensitems = JSON.parse(localStorage.getItem("klensitems"));
      console.log(this.klensitems);
    },
    delFavitems(index) {
      this.klensitems.splice(index, 1);
      localStorage.setItem("klensitems", JSON.stringify(this.klensitems));
    },
  },
};
</script>
