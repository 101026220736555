<template>
  <v-card class="overflow-hidden" elevation="0">
    <v-app-bar
      absolute
      color="white"
      dark
      scroll-target="#scrolling-techniques-3"
    >
      <v-btn to="/" icon>
        <v-icon color="black darken-2">mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-dialog transition="dialog-top-transition" max-width="400">
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="yellow"
              elevation="0"
              v-bind="attrs"
              v-on="on"
              @click="getstoreItem()"
              ><v-icon color="black darken-2">mdi-heart-outline</v-icon></v-btn
            >
          </template> -->
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark>Favourite List</v-toolbar>

              <v-list three-line>
                <template v-for="favs in favitems">
                  <v-list-item :key="favs.partno">
                    <v-list-item-avatar size="70">
                      <v-img src="../assets/emd.jpg"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-left font-weight-black cyan--text"
                        v-html="'Size -' + favs.size"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="favs.itemname"
                      ></v-list-item-subtitle>
                      <v-list-item-title
                        class="text-h5 font-weight-bold"
                        v-html="favs.mrp + '&#8377;'"
                      ></v-list-item-title>

                      <v-divider></v-divider>
                    </v-list-item-content>
                    <v-btn icon @click="delFavitems()"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-list-item>
                </template>
              </v-list>

              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
      <v-btn icon @click="dial(phone)">
        <v-icon color="blue">mdi-phone</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs
          v-model="activeTab"
          grow
          next-icon="mdi-arrow-right-bold-box-outline blue"
          prev-icon="mdi-arrow-left-bold-box-outline blue"
          show-arrows
        >
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#columnpipe" class="black--text"> COLUMN </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-3"
      class="overflow-y-auto"
      max-height="820"
    >
      <v-tabs-items v-model="activeTab" class="mt-15">
        <v-tab-item key="columnpipe" value="columnpipe" class="mt-10">
          <v-container>
            <v-row dense>
              <v-col v-for="(item, i) in columnpipe" :key="i" :cols="item.flex">
                <v-card
                  outlined
                  elevation="2"
                  color="#E31E24"
                  class="pa-2"
                  @click="goTodetail(item.id)"
                >
                  <v-img
                    :src="item.src"
                    class="white--text align-end"
                    height="200px"
                  >
                  </v-img>
                </v-card>
                <v-spacer></v-spacer>
                <v-divider light></v-divider>
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card width="650">
                    <v-row align="center">
                      <v-card-title
                        class="text-subtitle-2 ml-1 mt-1"
                        v-text="item.ItemGroup"
                      >
                      </v-card-title>
                    </v-row>
                  </v-card>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>

    <!-- <div height="70">
            <v-card-actions class="justify-center">
              <v-btn rounded color="pink" class="white--text">Enquiry</v-btn>
            </v-card-actions>
          </div> -->
  </v-card>
</template>

<style>
.myFont {
  font-family: "Mansalva", cursive;
}
</style>

<script>
import productData from "./JsonData/plumbing.json";

export default {
  props: ["product"],
  data: () => ({
    phone: 8022235726,
    dialog: false,
    show: false,
    activeTab: null,
    columnpipe: productData.column,

    favitems: [],
  }),
  beforeMount() {
    this.activeTab = this.product || "columnpipe";
  },
  methods: {
    onClickOutside() {
      this.dialog = false;
    },
    goTodetail(proId) {
      this.$router.push({
        name: "itemtable_column",
        params: { Pid: proId, product: this.activeTab, chl: this.childitem },
      });
    },

    dial: function (number) {
      window.location = "tel:" + number;
    },
    getstoreItem() {
      // this.favitems = JSON.parse(localStorage.getItem("favitems"));
      // console.log(this.favitems);
      if (localStorage.getItem("favitems"))
        this.favitems = JSON.parse(localStorage.getItem("favitems"));
      console.log(this.favitems);
    },
    delFavitems(index) {
      this.favitems.splice(index, 1);
      localStorage.setItem("favitems", JSON.stringify(this.favitems));
    },
  },
};
</script>
