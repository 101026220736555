<template>
  <v-card class="overflow-hidden" elevation="0">
    <v-app-bar absolute color="white" dark scroll-target="#scrolling-techniques-3">
      <v-btn to="/" icon>
        <v-icon color="black darken-2">mdi-arrow-left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn icon @click="dial(phone)">
        <v-icon color="blue">mdi-phone</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs v-model="activeTab" grow next-icon="mdi-arrow-right-bold-box-outline blue"
          prev-icon="mdi-arrow-left-bold-box-outline blue" show-arrows>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#cistern" class="black--text"> CISTERN </v-tab>
          <v-tab href="#cabinet" class="black--text"> CABINET </v-tab>
          <!-- <v-tab href="#coupling"> COUPLING/BALLCOCK </v-tab> -->
          <!-- <v-tab href="#jetspray"> JETSPRAY </v-tab> -->
          <v-tab href="#sink" class="black--text"> SINKS </v-tab>
          <!-- <v-tab href="#dispenser"> DISPENSER/SHELVES </v-tab> -->
          <v-tab href="#showers" class="black--text"> SHOWERS </v-tab>
          <v-tab href="#faucets" class="black--text"> FAUCETS </v-tab>
          <v-tab href="#bathaccess" class="black--text"> BATH ACCESS </v-tab>
          <!-- <v-tab href="#showerarms"> SHOWER ARMS </v-tab> -->
          <!-- <v-tab href="#flushing"> FLUSHING ACCESS</v-tab> -->
          <!-- <v-tab href="#otheraccess"> OTHER ACCESS </v-tab> -->
          <v-tab href="#connection" class="black--text">
            CONNECTION-WASTE-FOOTVALVE
          </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-sheet id="scrolling-techniques-3" class="overflow-y-auto" max-height="820">
      <v-tabs-items v-model="activeTab" class="mt-10">
        <v-tab-item class="mt-10" key="cistern" value="cistern">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/Single_Flushing.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in cistern" :key="i" :cols="item.flex">
                <v-card color="#A82682" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- <v-tab-item key="coupling" value="coupling">
        <v-container>
          <v-row dense>
            <v-col flex="12">
              <v-card outlined elevation="3">
                <v-img
                  src="../assets/access_main.png"
                  class="white--text align-end"
                  height="250px"
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card color="#5D4037" width="220" height="70">
                      <v-row align="center">
                        <v-card-title
                          class="white--text font-weight-bold text-h5 ml-1 mt-1"
                          >COUPLING / BALLCOCK
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-img>
              </v-card>
            </v-col>

            <v-col
              v-for="(item, i) in couplingballcock"
              :key="i"
              :cols="item.flex"
            >
              <v-card color="#01579B" outlined @click="goTodetail(item.id)">
                <v-img
                  :src="item.src"
                  class="white--text align-end"
                  height="200px"
                >
                </v-img>
                <v-spacer></v-spacer>
                <v-divider light></v-divider>
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card width="650">
                    <v-row align="center">
                      <v-card-title
                        class="text-subtitle-2 ml-1 mt-1"
                        v-text="item.title"
                      >
                      </v-card-title>
                    </v-row>
                  </v-card>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item> -->
        <!-- <v-tab-item value="jetspray">
        <v-container>
          <v-row dense>
            <v-col flex="12">
              <v-card outlined elevation="3">
                <v-img
                  src="../assets/jetspray.png"
                  class="white--text align-end"
                  height="250px"
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card color="#5D4037" width="150" height="50">
                      <v-row align="center">
                        <v-card-title
                          class="white--text font-weight-bold text-h5 ml-1 mt-1"
                          >JETSPRAY
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-img>
              </v-card>
            </v-col>

            <v-col v-for="(item, i) in jetspray" :key="i" :cols="item.flex">
              <v-card color="#01579B" outlined @click="goTodetail(item.id)">
                <v-img
                  :src="item.src"
                  class="white--text align-end"
                  height="200px"
                >
                </v-img>
                <v-spacer></v-spacer>
                <v-divider light></v-divider>
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card width="650">
                    <v-row align="center">
                      <v-card-title
                        class="text-subtitle-2 ml-1 mt-1"
                        v-text="item.title"
                      >
                      </v-card-title>
                    </v-row>
                  </v-card>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item> -->
        <v-tab-item value="sink" class="mt-10">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/sinks_main.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in sink" :key="i" :cols="item.flex">
                <v-card color="#A82682" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- <v-tab-item value="dispenser">
        <v-container>
          <v-row dense>
            <v-col flex="12">
              <v-card outlined elevation="3">
                <v-img
                  src="../assets/dispensers.png"
                  class="white--text align-end"
                  height="250px"
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card color="#5D4037" width="250" height="50">
                      <v-row align="center">
                        <v-card-title
                          class="white--text font-weight-bold text-h5 ml-1 mt-1"
                          >DISPENSER/SHELVES
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-img>
              </v-card>
            </v-col>

            <v-col
              v-for="(item, i) in dispensershelves"
              :key="i"
              :cols="item.flex"
            >
              <v-card color="#01579B" outlined @click="goTodetail(item.id)">
                <v-img
                  :src="item.src"
                  class="white--text align-end"
                  height="200px"
                >
                </v-img>
                <v-spacer></v-spacer>
                <v-divider light></v-divider>
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card width="650">
                    <v-row align="center">
                      <v-card-title
                        class="text-subtitle-2 ml-1 mt-1"
                        v-text="item.title"
                      >
                      </v-card-title>
                    </v-row>
                  </v-card>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item> -->
        <v-tab-item value="showers" class="mt-10">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/Shower_Slim.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in showers" :key="i" :cols="item.flex">
                <v-card color="#A82682" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="faucets" class="mt-10">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/faucets.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in faucets" :key="i" :cols="item.flex">
                <v-card color="#A82682" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="bathaccess" class="mt-10">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/bath_acce.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in bathaccess" :key="i" :cols="item.flex">
                <v-card color="#A82682" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- <v-tab-item value="showerarms">
        <v-container>
          <v-row dense>
            <v-col flex="12">
              <v-card outlined elevation="3">
                <v-img
                  src="../assets/emerald.png"
                  class="white--text align-end"
                  height="250px"
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card color="#5D4037" width="200" height="50">
                      <v-row align="center">
                        <v-card-title
                          class="white--text font-weight-bold text-h5 ml-1 mt-1"
                          >SHOWER ARMS
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-img>
              </v-card>
            </v-col>

            <v-col v-for="(item, i) in showerarms" :key="i" :cols="item.flex">
              <v-card color="#01579B" outlined @click="goTodetail(item.id)">
                <v-img
                  :src="item.src"
                  class="white--text align-end"
                  height="200px"
                >
                </v-img>
                <v-spacer></v-spacer>
                <v-divider light></v-divider>
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card width="650">
                    <v-row align="center">
                      <v-card-title
                        class="text-subtitle-2 ml-1 mt-1"
                        v-text="item.title"
                      >
                      </v-card-title>
                    </v-row>
                  </v-card>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item> -->
        <!-- <v-tab-item value="flushing">
        <v-container>
          <v-row dense>
            <v-col flex="12">
              <v-card outlined elevation="3">
                <v-img
                  src="../assets/emerald.png"
                  class="white--text align-end"
                  height="250px"
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card color="#5D4037" width="140" height="50">
                      <v-row align="center">
                        <v-card-title
                          class="white--text font-weight-bold text-h5 ml-1 mt-1"
                          >FLUSHING
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-img>
              </v-card>
            </v-col>

            <v-col
              v-for="(item, i) in flushingaccess"
              :key="i"
              :cols="item.flex"
            >
              <v-card color="#01579B" outlined @click="goTodetail(item.id)">
                <v-img
                  :src="item.src"
                  class="white--text align-end"
                  height="200px"
                >
                </v-img>
                <v-spacer></v-spacer>
                <v-divider light></v-divider>
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card width="650">
                    <v-row align="center">
                      <v-card-title
                        class="text-subtitle-2 ml-1 mt-1"
                        v-text="item.title"
                      >
                      </v-card-title>
                    </v-row>
                  </v-card>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item> -->
        <!-- <v-tab-item value="otheraccess">
        <v-container>
          <v-row dense>
            <v-col flex="12">
              <v-card outlined elevation="3">
                <v-img
                  src="../assets/emerald.png"
                  class="white--text align-end"
                  height="250px"
                >
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card color="#5D4037" width="190" height="50">
                      <v-row align="center">
                        <v-card-title
                          class="white--text font-weight-bold text-h5 ml-1 mt-1"
                          >OTHER ACCESS
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-img>
              </v-card>
            </v-col>

            <v-col v-for="(item, i) in otheraccess" :key="i" :cols="item.flex">
              <v-card color="#01579B" outlined @click="goTodetail(item.id)">
                <v-img
                  :src="item.src"
                  class="white--text align-end"
                  height="200px"
                >
                </v-img>
                <v-spacer></v-spacer>
                <v-divider light></v-divider>
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-card width="650">
                    <v-row align="center">
                      <v-card-title
                        class="text-subtitle-2 ml-1 mt-1"
                        v-text="item.title"
                      >
                      </v-card-title>
                    </v-row>
                  </v-card>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item> -->
        <v-tab-item value="connection" class="mt-10">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/connec.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in connection" :key="i" :cols="item.flex">
                <v-card color="#A82682" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="cabinet" class="mt-10">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/cabinet.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in cabinets" :key="i" :cols="item.flex">
                <v-card color="#89C5A4" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-card>
</template>



<script>
import productData from "./JsonData/product.json";

export default {
  props: ["product"],
  data: () => ({
    phone: 8022235726,
    dialog: false,
    show: false,
    activeTab: null,

    dialogdata: {
      id: "",
      color: "",
      src: "",
      title: "",
      itemname: "",
      partno: "",
      flex: "",
      mrp: "",
      packstd: "",
      colorrange: "",
    },

    // couplingballcock: productData.couplingballcock,
    // jetspray: productData.jetspray,
    sink: productData.sink,
    // dispensershelves: productData.dispensershelves,
    showers: productData.showers,
    faucets: productData.faucets,
    bathaccess: productData.bathaccess,
    // showerarms: productData.showerarms,
    // flushingaccess: productData.flushingaccess,
    // otheraccess: productData.otheraccess,
    cistern: productData.cistern,
    connection: productData.connection,
    cabinets: productData.cabinets,
  }),
  beforeMount() {
    this.activeTab = this.product || "cistern";
  },
  methods: {
    onClickOutside() {
      this.dialog = false;
    },

    goTodetail(proId) {
      this.$router.push({
        name: "otherDetails",
        params: { Pid: proId, product: this.activeTab },
      });
    },
    dial: function (number) {
      window.location = "tel:" + number;
    },
  },
};
</script>
