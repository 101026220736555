<template>
  <v-card class="overflow-hidden" elevation="0">
    <v-app-bar absolute color="white" dark scroll-target="#scrolling-techniques-3">
      <v-btn to="/" icon>
        <v-icon color="black darken-2">mdi-arrow-left</v-icon>
      </v-btn>
    </v-app-bar>
    <v-sheet id="scrolling-techniques-3" class="overflow-y-auto mt-15" max-height="820">
      <v-container>
        <v-row><v-card-title>Download Price List</v-card-title></v-row>
        <v-row dense>
          <v-col cols="6" sm="4" md="4">
            <v-card href="https://www.rakshapipes.com/images/product_catalog_images/download/cpvc.pdf" target="_blank">
              <v-img src="../assets/pdf.png" class="white--text align-end" height="100px">
              </v-img>
              <v-card-title class="text-subtitle-1 font-weight-bold">CPVC</v-card-title>
              <v-card-subtitle>15-11-2023</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="4">
            <v-card href="https://www.rakshapipes.com/images/product_catalog_images/download/upvc_PL.pdf"
              target="_blank">
              <v-img src="../assets/pdf.png" class="white--text align-end" height="100px">
              </v-img>
              <v-card-title class="text-subtitle-1 font-weight-bold">uPVC</v-card-title>
              <v-card-subtitle>15-11-2023</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="4">
            <v-card href="https://www.rakshapipes.com/images/product_catalog_images/download/PTMT.pdf" target="_blank">
              <v-img src="../assets/pdf.png" class="white--text align-end" height="100px">
              </v-img>
              <v-card-title class="text-subtitle-1 font-weight-bold">PTMT</v-card-title>
              <v-card-subtitle>15-06-2024</v-card-subtitle>
            </v-card>
          </v-col>

          <v-col cols="6" sm="4" md="4">
            <v-card href="https://www.rakshapipes.com/images/product_catalog_images/download/durofit.pdf"
              target="_blank">
              <v-img src="../assets/pdf.png" class="white--text align-end" height="100px">
              </v-img>
              <v-card-title class="text-subtitle-1 font-weight-bold">DUROFIT</v-card-title>
              <v-card-subtitle>15-11-2023</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="4">
            <v-card href="https://www.rakshapipes.com/images/product_catalog_images/download/cemfit.pdf"
              target="_blank">
              <v-img src="../assets/pdf.png" class="white--text align-end" height="100px">
              </v-img>
              <v-card-title class="text-subtitle-1 font-weight-bold">CEMFIT</v-card-title>
              <v-card-subtitle>15-11-2023</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="4">
            <v-card href="https://www.rakshapipes.com/images/product_catalog_images/download/Sonafit_Equal.pdf"
              target="_blank">
              <v-img src="../assets/pdf.png" class="white--text align-end" height="100px">
              </v-img>
              <v-card-title class="text-subtitle-1 font-weight-bold">SONAFIT</v-card-title>
              <v-card-subtitle>15-11-2023</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="4">
            <v-card href="https://www.rakshapipes.com/images/product_catalog_images/download/equal_cemfit.pdf"
              target="_blank">
              <v-img src="../assets/pdf.png" class="white--text align-end" height="100px">
              </v-img>
              <v-card-title class="text-subtitle-1 font-weight-bold">EQUALFITT</v-card-title>
              <v-card-subtitle>15-11-2023</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="4">
            <v-card href="https://www.rakshapipes.com/images/product_catalog_images/download/UG_FITT.pdf"
              target="_blank">
              <v-img src="../assets/pdf.png" class="white--text align-end" height="100px">
              </v-img>
              <v-card-title class="text-subtitle-1 font-weight-bold">UNDERGROUND</v-card-title>
              <v-card-subtitle>15-11-2023</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="4">
            <v-card href="https://www.rakshapipes.com/images/product_catalog_images/download/PN-16.pdf" target="_blank">
              <v-img src="../assets/pdf.png" class="white--text align-end" height="100px">
              </v-img>
              <v-card-title class="text-subtitle-1 font-weight-bold">PN-16</v-card-title>
              <v-card-subtitle>15-11-2023</v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="6" sm="4" md="4">
            <v-card href="https://www.rakshapipes.com/images/product_catalog_images/download/common_fittings.pdf"
              target="_blank">
              <v-img src="../assets/pdf.png" class="white--text align-end" height="100px">
              </v-img>
              <v-card-title class="text-subtitle-1 font-weight-bold">COMMON FITT</v-card-title>
              <v-card-subtitle>15-11-2023</v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- <v-row dense>
          <v-card-title>Download Product Catalog</v-card-title>
          <v-col v-for="(item, i) in prodCatalog" :key="i" :cols="item.flex">
            <v-card color="red" @click="goTodetail()">
              <v-img
                :src="item.src"
                class="white--text align-end"
                height="100px"
              >
              </v-img>
              <div class="d-flex flex-no-wrap justify-space-between">
                <v-row>
                  <v-card-title class="font-weight-black text-h6 ml-1 mt-1">
                    <h4 class="white--text">
                      {{ item.prodName }}
                    </h4>
                  </v-card-title>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row> -->
      </v-container>
    </v-sheet>
  </v-card>
</template>


<style>
.myFont {
  font-family: "Mansalva", cursive;
}
</style>

<script>
export default {
  props: ["product"],
  data: () => ({
    phone: 8022235726,
    dialog: false,
    show: false,
    activeTab: null,
  }),

  methods: {
    dial: function (number) {
      window.location = "tel:" + number;
    },
  },
};
</script>
