<template>
  <v-app>
    <v-app-bar
      absolute
      color="white"
      dark
      scroll-target="#scrolling-techniques-3"
    >
      <v-btn v-bind:to="'/plumbing_cpvc/' + product" icon>
        <v-icon color="black darken-2">mdi-arrow-left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn icon @click="dial(phone)">
        <v-icon color="black darken-2">mdi-phone</v-icon>
      </v-btn>
    </v-app-bar>

    <v-sheet
      id="scrolling-techniques-3"
      class="overflow-y-auto"
      max-height="820"
    >
      <div class="col-md-12">
        <div v-for="(product, index) in upvcfitt" :key="index">
          <div v-if="proId == product.id">
            <v-card elevation="0" color="#E31E24" class="mt-15">
              <v-row justify="center">
                <img
                  :src="product.src"
                  class="img-fluid justify-canter"
                  height="340"
                />
              </v-row>
            </v-card>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
              centered
              color="primary"
              transition="v-snack-transition"
              ><v-icon>mdi-heart-outline</v-icon>
              Added to favourite
            </v-snackbar>
          </div>
        </div>
        <v-card>
          <div v-for="product in upvcfitt" :key="product.partno">
            <div v-if="proId == product.id">
              <v-row>
                <v-card>
                  <v-simple-table height="375">
                    <tbody>
                      <tr
                        v-for="itemdet in product.childitem"
                        :key="itemdet.partno"
                      >
                        <v-card class="mx-auto mt-1" elevation="1">
                          <v-list-item three-line>
                            <v-list-item-content>
                              <div class="text-overline mb-1 blue--text">
                                size -{{ itemdet.size }}
                              </div>
                              <v-list-item-title
                                class="text-h5 mb-1 font-weight-bold"
                              >
                                <v-icon color="black">mdi-currency-inr</v-icon>
                                {{ itemdet.mrp }}
                                <div class="text-overline">MRP Per Mtr/Pcs</div>
                              </v-list-item-title>

                              <v-list-item-subtitle class="text-subtitle-1"
                                >Part No :
                                {{ itemdet.partno }}</v-list-item-subtitle
                              >

                              <v-chip-group>
                                <v-chip
                                  >Std- Box/Bag -
                                  {{ itemdet.packstdBox }}</v-chip
                                >
                                <v-chip
                                  >Std- Pkt - {{ itemdet.packstdCover }}</v-chip
                                >
                              </v-chip-group>
                            </v-list-item-content>

                            <v-card-actions>
                              <v-btn
                                @click="storeSelectedItem(itemdet)"
                                class="mx-2"
                                fab
                                dark
                                medium
                                color="yellow"
                                ><v-icon color="black darken-2"
                                  >mdi-heart-plus-outline</v-icon
                                ></v-btn
                              >
                            </v-card-actions>
                          </v-list-item>
                        </v-card>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-row>
            </div>
          </div>
        </v-card>
      </div>
      <v-card height="100px">
        <v-footer fixed padless>
          <v-card color="#E31E24" width="100%" tile elevation="0">
            <v-card-actions class="pa-4">
              <span class="font-weight-medium white--text">Product Rating</span>
              <v-spacer></v-spacer>
              <span class="yellow--text text-caption mr-2">
                ({{ rating }})
              </span>
              <v-rating
                v-model="rating"
                background-color="yellow"
                color="yellow accent-4"
                dense
                half-increments
                hover
                size="18"
              ></v-rating>
            </v-card-actions>
          </v-card>
        </v-footer>
      </v-card>
    </v-sheet>
  </v-app>
</template>

<style>
.myFont {
  font-family: "Mansalva", cursive;
}
</style>

<script>
import upvcfitt from "./JsonData/plumbingDet.json";

export default {
  name: "itemtable_cpvc",
  data() {
    return {
      proId: this.$route.params.Pid,
      product: this.$route.params.product,
      upvcfitt: upvcfitt,
      rating: 0,
      plumfavitems: [],
      snackbar: false,
      timeout: 1000,
    };
  },
  methods: {
    storeSelectedItem(itemdet) {
      this.plumfavitems.push(itemdet);
      const currentItems = localStorage.getItem("plumfavitems") || "[]";

      localStorage.setItem(
        "plumfavitems",
        JSON.stringify(JSON.parse(currentItems).concat(this.plumfavitems))
      ) == true;

      this.snackbar = true;

      console.log(this.plumfavitems);
    },
  },
};
</script>
