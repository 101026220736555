<template>
  <v-app>
    <v-app-bar
      absolute
      color="white"
      dark
      scroll-target="#scrolling-techniques-3"
    >
      <v-btn v-bind:to="'/agriculture_column/' + product" icon>
        <v-icon color="black darken-2">mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="dial(phone)">
        <v-icon color="black darken-2">mdi-phone</v-icon>
      </v-btn>
    </v-app-bar>

    <v-sheet
      id="scrolling-techniques-3"
      class="overflow-y-auto"
      max-height="820"
    >
      <div class="col-md-12">
        <div v-for="(product, index) in column" :key="index">
          <div v-if="proId == product.id">
            <v-card elevation="0" color="#106FA5" class="mt-15">
              <v-row justify="center">
                <img
                  :src="product.src"
                  class="img-fluid justify-canter"
                  height="340"
                />
              </v-row>
            </v-card>
            <!-- <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
              centered
              color="primary"
              transition="v-snack-transition"
              ><v-icon>mdi-heart-outline</v-icon>
              Added to favourite
            </v-snackbar> -->
          </div>
        </div>
        <v-card>
          <div v-for="product in column" :key="product.partno">
            <div v-if="proId == product.id">
              <v-row>
                <v-card width="500">
                  <v-simple-table height="375">
                    <thead>
                      <tr>
                        <th class="text-left">PartNo</th>
                        <th class="text-left">Sizes</th>
                        <th class="text-left">Box/Bag-Std</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="itemdet in product.childitem"
                        :key="itemdet.partno"
                      >
                        <td>{{ itemdet.partno }}</td>
                        <td>{{ itemdet.size }}</td>

                        <td>{{ itemdet.packstdBox }}</td>
                        <!-- <v-btn
                          @click.once="storeSelectedItem(itemdet)"
                          class="mx-2"
                          fab
                          dark
                          small
                          color="yellow"
                          ><v-icon color="black darken-2"
                            >mdi-heart-plus-outline</v-icon
                          ></v-btn
                        > -->
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-row>
            </div>
          </div>
        </v-card>
      </div>
      <v-card height="100px">
        <v-footer fixed padless>
          <v-card color="#106FA5" width="100%" tile elevation="0">
            <v-card-actions class="pa-4">
              <span class="font-weight-medium white--text">Product Rating</span>
              <v-spacer></v-spacer>
              <span class="yellow--text text-caption mr-2">
                ({{ rating }})
              </span>
              <v-rating
                v-model="rating"
                background-color="yellow"
                color="yellow accent-4"
                dense
                half-increments
                hover
                size="18"
              ></v-rating>
            </v-card-actions>
          </v-card>
        </v-footer>
      </v-card>
    </v-sheet>
  </v-app>
</template>

<style>
.myFont {
  font-family: "Mansalva", cursive;
}
</style>

<script>
import column from "./JsonData/plumbingDet.json";

export default {
  name: "itemtable_column",
  data() {
    return {
      proId: this.$route.params.Pid,
      product: this.$route.params.product,
      column: column,
      rating: 0,
      clique: 0,
      // favitems: [],
      // snackbar: false,
      // timeout: 1000,
    };
  },
  watch: {
    favwatch() {
      this.favitems;
    },
  },
  //

  // methods: {
  //   storeSelectedItem(itemdet) {
  //     this.favitems.push(itemdet);
  //     const currentItems = localStorage.getItem("favitems") || "[]";

  //     localStorage.setItem(
  //       "favitems",
  //       JSON.stringify(JSON.parse(currentItems).concat(this.favitems))
  //     ) == true;

  //     this.snackbar = true;

  //     console.log(this.favitems);
  //   },
  // },
};
</script>
