<template>
  <v-card class="overflow-hidden" elevation="0">
    <v-app-bar
      absolute
      color="white"
      dark
      scroll-target="#scrolling-techniques-3"
    >
      <v-btn to="/" icon>
        <v-icon color="black darken-2">mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="dial(phone)">
        <v-icon color="blue">mdi-phone</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs
          v-model="activeTab"
          grow
          next-icon="mdi-arrow-right-bold-box-outline blue"
          prev-icon="mdi-arrow-left-bold-box-outline blue"
          show-arrows
        >
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#durofitpipe" class="black--text"> PIPES </v-tab>
          <v-tab href="#durofitt" class="black--text"> FITTINGS </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-3"
      class="overflow-y-auto"
      max-height="820"
    >
      <v-tabs-items v-model="activeTab" class="mt-15">
        <v-tab-item value="durofitt" class="mt-10">
          <v-container>
            <v-row dense>
              <v-col v-for="(item, i) in duroFitt" :key="i" :cols="item.flex">
                <v-card color="#007DCC" outlined @click="goTodetail(item.id)">
                  <v-img
                    :src="item.src"
                    class="white--text align-end"
                    height="200px"
                  >
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title
                          class="text-subtitle-2 ml-1 mt-1"
                          v-text="item.ItemGroup"
                        >
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item key="durofitpipe" value="durofitpipe" class="mt-10">
          <v-container>
            <v-row dense>
              <v-col
                v-for="(item, i) in durofitpipe"
                :key="i"
                :cols="item.flex"
              >
                <v-card color="#007DCC" outlined @click="goTodetail(item.id)">
                  <v-img
                    :src="item.src"
                    class="white--text align-end"
                    height="200px"
                  >
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title
                          class="text-subtitle-2 ml-1 mt-1"
                          v-text="item.ItemGroup"
                        >
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-card>
</template>



<script>
import productData from "./JsonData/plumbing.json";

export default {
  props: ["product"],
  data: () => ({
    props: ["product"],
    phone: 8022235726,
    dialog: false,
    show: false,
    activeTab: null,
    duroFitt: productData.duroFitt,
    durofitpipe: productData.durofitpipe,
  }),
  beforeMount() {
    this.activeTab = this.product || "durofitt";
  },

  methods: {
    onClickOutside() {
      this.dialog = false;
    },
    goTodetail(proId) {
      this.$router.push({
        name: "itemtable_durofit",
        params: { Pid: proId, product: this.activeTab },
      });
    },
    dial: function (number) {
      window.location = "tel:" + number;
    },
  },
};
</script>
