<template>
  <v-card class="overflow-hidden" elevation="0">
    <v-app-bar absolute color="white" dark scroll-target="#scrolling-techniques-3">
      <v-btn to="/" icon>
        <v-icon color="black darken-2">mdi-arrow-left</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn icon @click="dial(phone)">
        <v-icon color="blue">mdi-phone</v-icon>
      </v-btn>

      <template v-slot:extension>
        <v-tabs v-model="activeTab" grow next-icon="mdi-arrow-right-bold-box-outline blue"
          prev-icon="mdi-arrow-left-bold-box-outline blue" show-arrows>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#emd" class="black--text"> EMERALD </v-tab>
          <v-tab href="#emdplus" class="black--text"> EMERALD PLUS </v-tab>
          <v-tab href="#emdelite" class="black--text"> EMERALD ELITE </v-tab>
          <v-tab href="#jade" class="black--text"> JADE </v-tab>
          <v-tab href="#jadeelite" class="black--text"> JADE ELITE </v-tab>
          <v-tab href="#jadeplus" class="black--text"> JADE PLUS </v-tab>
          <v-tab href="#galaxy" class="black--text"> GALAXY </v-tab>
          <v-tab href="#galaxyelite" class="black--text"> GALAXY ELITE </v-tab>
          <v-tab href="#ruby" class="black--text"> RUBY </v-tab>
          <v-tab href="#coral" class="black--text"> CORAL </v-tab>
          <v-tab href="#coralelite" class="black--text"> CORAL ELITE</v-tab>
          <v-tab href="#aqua" class="black--text"> AQUA </v-tab>
          <v-tab href="#silver" class="black--text"> SILVER </v-tab>
          <v-tab href="#silverelite" class="black--text"> SILVER ELITE </v-tab>
          <v-tab href="#dmd" class="black--text"> DIAMOND </v-tab>
          <v-tab href="#std" class="black--text"> STANDARD </v-tab>
          <v-tab href="#stdelite" class="black--text"> STANDARD ELITE </v-tab>
          <v-tab href="#gold" class="black--text"> GOLD </v-tab>
          <v-tab href="#superb" class="black--text"> SUPERB </v-tab>
          <v-tab href="#superbelite" class="black--text"> SUPERB ELITE</v-tab>
          <v-tab href="#superbplus" class="black--text"> SUPERB PLUS</v-tab>
          <v-tab href="#sona" class="black--text"> SONA</v-tab>
          <v-tab href="#sonaelite" class="black--text"> SONA ELITE</v-tab>
          <v-tab href="#royal" class="black--text"> ROYAL</v-tab>
          <v-tab href="#royalelite" class="black--text"> ROYAL ELITE</v-tab>
          <v-tab href="#royalgreen" class="black--text"> ROYAL GREEN</v-tab>
          <v-tab href="#durotap" class="black--text"> Duro Tap</v-tab>
          <v-tab href="#woodlite45" class="black--text"> Woodlite-45</v-tab>
          <v-tab href="#woodlite90" class="black--text"> Woodlite-90</v-tab>
          <v-tab href="#opal" class="black--text">Opal</v-tab>
          <v-tab href="#opalelite" class="black--text">Opal Elite</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-sheet id="scrolling-techniques-3" class="overflow-y-auto" max-height="820">
      <v-tabs-items v-model="activeTab" class="mt-15">
        <v-tab-item value="emd">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/emd.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in ptmtemerald" :key="i" :cols="item.flex">
                <v-card color="#0B674E" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="emdplus" key="emdplus">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/emd_plus.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in emeraldplus" :key="i" :cols="item.flex">
                <v-card color="#035A35" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="emdelite">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/emd_elite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in emeraldelite" :key="i" :cols="item.flex">
                <v-card color="#145E34" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="jade">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/jade.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in jade" :key="i" :cols="item.flex">
                <v-card color="#22B4B2" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="jadeelite">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/jade_elite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in jadeelite" :key="i" :cols="item.flex">
                <v-card color="#0D9188" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="jadeplus">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/jade_plus.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in jadeplus" :key="i" :cols="item.flex">
                <v-card color="#0A8C7B" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="galaxy">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/galx.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in galaxy" :key="i" :cols="item.flex">
                <v-card color="#620978" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="galaxyelite">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/galx_elite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in galaxyelite" :key="i" :cols="item.flex">
                <v-card color="#694291" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="ruby">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/ruby.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in ruby" :key="i" :cols="item.flex">
                <v-card color="#B80F12" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="coral">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/coral.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in coral" :key="i" :cols="item.flex">
                <v-card color="#F86F65" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="coralelite">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/coral_elite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in coralelite" :key="i" :cols="item.flex">
                <v-card color="#C7453D" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="aqua">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/aqua.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in aqua" :key="i" :cols="item.flex">
                <v-card color="#52B3C3" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="silver">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/silver.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in silver" :key="i" :cols="item.flex">
                <v-card color="#7E7E7F" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="silverelite">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/slvr_elite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in silverelite" :key="i" :cols="item.flex">
                <v-card color="#666666" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="dmd">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/dmd.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in diamond" :key="i" :cols="item.flex">
                <v-card color="#F09214" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="std">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/stand.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in standard" :key="i" :cols="item.flex">
                <v-card color="#636362" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="stdelite">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/stand_elite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in standardelite" :key="i" :cols="item.flex">
                <v-card color="#404040" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="gold">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/gold.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in gold" :key="i" :cols="item.flex">
                <v-card color="#99711A" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="superb">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/superb.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in superb" :key="i" :cols="item.flex">
                <v-card color="#804823" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="superbelite">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/superb_elite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in superbelite" :key="i" :cols="item.flex">
                <v-card color="#1F1D22" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="superbplus">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/superb_plus.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in superbplus" :key="i" :cols="item.flex">
                <v-card color="#EE751C" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="sona">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/sona.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in sona" :key="i" :cols="item.flex">
                <v-card color="#C58B33" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="sonaelite">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/sonaelite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in sonaelite" :key="i" :cols="item.flex">
                <v-card color="#81531D" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="royal">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/royal.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in royal" :key="i" :cols="item.flex">
                <v-card color="#2B7832" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="royalelite">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/royalelite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in royalelite" :key="i" :cols="item.flex">
                <v-card color="#36B448" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="royalgreen">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/royalgarden.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in royalgreen" :key="i" :cols="item.flex">
                <v-card color="#CCF046" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="durotap">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/durotap.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in durotap" :key="i" :cols="item.flex">
                <v-card color="#9F3227" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="woodlite45">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/woodlite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in woodlite45" :key="i" :cols="item.flex">
                <v-card color="#FFCF95" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="woodlite90">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/woodlite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in woodlite90" :key="i" :cols="item.flex">
                <v-card color="#FFCF95" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="opal">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/opal.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in opal" :key="i" :cols="item.flex">
                <v-card color="#FF78AA" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="opalelite">
          <v-container>
            <v-row dense>
              <v-col flex="12">
                <v-card outlined elevation="3">
                  <v-img src="../assets/opalelite.jpg" class="white--text align-end" height="250px">
                  </v-img>
                </v-card>
              </v-col>

              <v-col v-for="(item, i) in opalelite" :key="i" :cols="item.flex">
                <v-card color="#EF88B1" outlined @click="goTodetail(item.id)">
                  <v-img :src="item.src" class="white--text align-end" height="200px">
                  </v-img>
                  <v-spacer></v-spacer>
                  <v-divider light></v-divider>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card width="650">
                      <v-row align="center">
                        <v-card-title class="text-subtitle-2 ml-1 mt-1" v-text="item.title">
                        </v-card-title>
                      </v-row>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-card>
</template>

<script>
import productData from "./JsonData/product.json";

export default {
  props: ["product"],
  data: () => ({
    props: ["product"],
    phone: 8022235726,
    dialog: false,
    show: false,
    activeTab: null,
    dialogdata: {
      id: "",
      color: "",
      src: "",
      title: "",
      itemname: "",
      partno: "",
      flex: "",
      mrp: "",
      packstd: "",
      colorrange: "",
      ptmtitems: [],
    },
    emd: [],

    ptmtemerald: productData.ptmtemerald,
    emeraldplus: productData.emeraldplus,
    emeraldelite: productData.emeraldelite,
    jade: productData.jade,
    jadeelite: productData.jadeelite,
    jadeplus: productData.jadeplus,
    galaxy: productData.galaxy,
    galaxyelite: productData.galaxyelite,
    ruby: productData.ruby,
    coral: productData.coral,
    coralelite: productData.coralelite,
    aqua: productData.aqua,
    silver: productData.silver,
    silverelite: productData.silverelite,
    diamond: productData.diamond,
    standard: productData.standard,
    standardelite: productData.standardelite,
    gold: productData.gold,
    superb: productData.superb,
    superbelite: productData.superbelite,
    superbplus: productData.superbplus,
    sona: productData.sona,
    sonaelite: productData.sonaelite,
    royal: productData.royal,
    royalelite: productData.royalelite,
    royalgreen: productData.royalgreen,
    durotap: productData.durotap,
    woodlite90: productData.woodlite90,
    woodlite45: productData.woodlite45,
    opal: productData.opal,
    opalelite: productData.opalelite
  }),
  beforeMount() {
    this.activeTab = this.product || "emdplus";
  },
  methods: {
    onClickOutside() {
      this.dialog = false;
    },

    setVal(emd) {
      console.log(emd);
      this.dialogdata.title = emd.title;
      this.dialogdata.src = emd.src;
      this.dialogdata.itemname = emd.itemname;
      this.dialogdata.partno = emd.partno;
      this.dialogdata.mrp = emd.mrp;
      this.dialogdata.packstd = emd.packstd;
      this.dialogdata.colorrange = emd.colorrange;
    },
    goTodetail(proId) {
      this.$router.push({
        name: "ItemDetails",
        params: { Pid: proId, product: this.activeTab },
      });
    },
    dial: function (number) {
      window.location = "tel:" + number;
    },
    getstoreItem() {
      // this.favitems = JSON.parse(localStorage.getItem("favitems"));
      // console.log(this.favitems);
      if (localStorage.getItem("ptmtitems"))
        this.ptmtitems = JSON.parse(localStorage.getItem("ptmtitems"));
      console.log(this.ptmtitems);
    },
    delFavitems(index) {
      this.ptmtitems.splice(index, 1);
      localStorage.setItem("ptmtitems", JSON.stringify(this.ptmtitems));
    },
  },
};
</script>
