<template>
    <v-app>
        <v-app-bar absolute color="white" dark scroll-target="#scrolling-techniques-3">
            <v-btn v-bind:to="'/cleaningSol/' + product" icon>
                <v-icon color="black darken-2">mdi-arrow-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="pink" icon href="whatsapp://send?text=https://www.rakshapipes.com"
                data-action="share/whatsapp/share">
                <v-icon>mdi-share-variant</v-icon>
            </v-btn>
            <v-btn icon @click="dial(phone)">
                <v-icon color="black darken-2">mdi-phone</v-icon>
            </v-btn>
        </v-app-bar>
        <v-sheet id="scrolling-techniques-3" class="overflow-y-auto" max-height="820">
            <div class="col-md-12">
                <div v-for="(product, index) in klens" :key="index">
                    <div v-if="proId == product.id">
                        <v-card elevation="0" color="#E0F2F1" class="mt-15">
                            <v-row justify="center">
                                <img height="350" :src="product.src" class="img-fluid justify-canter" />
                            </v-row>
                            <v-card elevation="2" color="white" height="350">
                                <v-btn color="white" absolute top right fab @click.once="storeSelectedItem(product)">
                                    <v-icon>mdi-heart-outline</v-icon>
                                </v-btn>

                                <v-snackbar v-model="snackbar" :timeout="timeout" centered color="black" fab
                                    transition="v-snack-transition"><v-icon>mdi-heart-outline</v-icon>
                                    Added to favourite
                                </v-snackbar>

                                <v-card-title class="font-weight-bold text-h4 justify-center darkgrey--text">{{
                                    product.title }}
                                </v-card-title>
                                <v-card-subtitle class="font-weight-medium text-center subtitle-1">
                                    {{ product.itemname }}
                                </v-card-subtitle>
                                <v-divider light></v-divider>
                                <v-card-actions class="pa-5 font-weight-black text-h5">MRP/Pcs
                                    <v-spacer></v-spacer>
                                    <span class="font-weight-black text-h5"><span>&#8377;</span>{{ product.mrp }}</span>
                                </v-card-actions>
                                <v-divider light></v-divider>
                                <v-card-actions class="pa-3 font-weight-medium">
                                    Part No
                                    <v-spacer></v-spacer>
                                    <span class="font-weight-regular">{{ product.partno }}</span>
                                </v-card-actions>
                                <v-divider light></v-divider>
                                <v-card-actions class="pa-3 font-weight-medium">
                                    Colors
                                    <v-spacer></v-spacer>
                                    <span class="font-weight-regular">{{
                                        product.colorrange
                                    }}</span>
                                </v-card-actions>
                                <v-divider light></v-divider>
                                <v-card-actions class="pa-3 font-weight-medium">
                                    Pack Standard
                                    <v-spacer></v-spacer>
                                    <span class="font-weight-regular">{{ product.packstd }}/Box</span>
                                </v-card-actions>

                                <v-card-actions class="pa-3 font-weight-medium">
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-card>

                        <!-- <div height="70">
              <v-card-actions class="justify-center">
                <v-btn rounded color="pink" class="white--text">Enquiry</v-btn>
              </v-card-actions>
            </div> -->
                    </div>
                </div>
            </div>
            <v-card height="100px">
                <v-footer fixed padless>
                    <v-card color="#00897B" width="100%" tile elevation="0">
                        <v-card-actions class="pa-4">
                            <span class="font-weight-medium white--text">Product Rating</span>
                            <v-spacer></v-spacer>
                            <span class="yellow--text text-caption mr-2">
                                ({{ rating }})
                            </span>
                            <v-rating v-model="rating" background-color="yellow" color="yellow accent-4" dense
                                half-increments hover size="18"></v-rating>
                        </v-card-actions>
                    </v-card>
                </v-footer>
            </v-card>
        </v-sheet>
    </v-app>
</template>
  
<style>
.myFont {
    font-family: "Mansalva", cursive;
}
</style>
  
<script>
import productData from "./JsonData/productDet.json";

export default {
    data() {
        return {
            rating: 0,
            proId: this.$route.params.Pid,
            product: this.$route.params.product,
            klens: productData,
            klensitems: [],
            snackbar: false,
            timeout: 1000,
        };
    },
    methods: {
        storeSelectedItem(product) {
            this.klensitems.push(product);
            const currentItems = localStorage.getItem("klensitems") || "[]";

            localStorage.setItem(
                "klensitems",
                JSON.stringify(JSON.parse(currentItems).concat(this.klensitems))
            ) == true;

            this.snackbar = true;

            console.log(this.klensitems);
        },
    },
};
</script>
  