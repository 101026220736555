<template>
  <v-app>
    <v-app-bar
      absolute
      color="white"
      dark
      scroll-target="#scrolling-techniques-3"
    >
      <v-btn to="/" icon>
        <v-icon color="black darken-2">mdi-arrow-left</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-tabs
          v-model="activeTab"
          grow
          next-icon="mdi-arrow-right-bold-box-outline blue"
          prev-icon="mdi-arrow-left-bold-box-outline blue"
          show-arrows
        >
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#sanitary" class="black--text"> SANITARY </v-tab>
          <v-tab href="#plumbing" class="black--text"> PLUMBING </v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-3"
      class="overflow-y-auto mt-2"
      max-height="820"
    >
      <v-tabs-items v-model="activeTab" class="mt-8">
        <v-tab-item value="sanitary" class="mt-10">
          <v-container class="mt-15">
            <v-row><v-card-title>Favourite List</v-card-title></v-row>
            <v-row dense>
              <v-col cols="12" sm="4" md="12">
                <v-list three-line>
                  <template>
                    <v-list-item
                      v-for="(favs, index) in ptmtitems"
                      :key="favs.id"
                    >
                      <v-list-item-avatar color="primary" size="70">
                        <v-img :src="favs.src"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-left font-weight-black cyan--text"
                          v-html="favs.partno"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="favs.itemname"
                        ></v-list-item-subtitle>

                        <v-list-item-title
                          class="text-h5 font-weight-bold"
                          v-html="'&#8377;' + favs.mrp"
                        ></v-list-item-title>

                        <v-divider></v-divider>
                      </v-list-item-content>
                      <v-btn icon @click="delFavitemsan(index)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <v-tab-item value="plumbing" class="mt-8">
          <v-container class="mt-15">
            <v-row><v-card-title>Favourite List</v-card-title></v-row>
            <v-row dense>
              <v-col cols="12" sm="4" md="12">
                <v-list three-line>
                  <template>
                    <v-list-item
                      v-for="(favs, index) in plumfavitems"
                      :key="favs.id"
                    >
                      <v-list-item-avatar color="primary" size="70">
                        <v-img :src="favs.src"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-left font-weight-black cyan--text"
                          v-html="favs.partno"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="favs.itemname"
                        ></v-list-item-subtitle>

                        <v-list-item-title
                          class="text-h5 font-weight-bold"
                          v-html="'&#8377;' + favs.mrp"
                        ></v-list-item-title>

                        <v-divider></v-divider>
                      </v-list-item-content>
                      <v-btn icon @click="delFavitemplm(index)"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-app>
</template>


<style>
.myFont {
  font-family: "Mansalva", cursive;
}
</style>

<script>
export default {
  data: () => ({
    phone: 8022235726,
    dialog: false,
    show: false,
    activeTab: null,
    plumfavitems: [],
    ptmtitems: [],
  }),
  created() {
    if (localStorage.getItem("plumfavitems"))
      this.plumfavitems = JSON.parse(localStorage.getItem("plumfavitems"));
    console.log(this.plumfavitems);
    if (localStorage.getItem("ptmtitems"))
      this.ptmtitems = JSON.parse(localStorage.getItem("ptmtitems"));
    console.log(this.ptmtitems);
  },

  methods: {
    dial: function (number) {
      window.location = "tel:" + number;
    },

    delFavitemplm(index) {
      this.plumfavitems.splice(index, 1);
      localStorage.setItem("plumfavitems", JSON.stringify(this.plumfavitems));
    },
    delFavitemsan(index) {
      this.ptmtitems.splice(index, 1);
      localStorage.setItem("ptmtitems", JSON.stringify(this.ptmtitems));
    },
  },
};
</script>
