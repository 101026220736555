<template>
  <v-app id="app">
    <transition name="moveInUp">
      <router-view />
    </transition>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style scoped>
.moveInUp-enter-active {
  animation: fadeIn 0.4s ease-in;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.moveInUp-leave-active {
  animation: moveInUp 0.3s ease-in;
}
@keyframes moveInUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200px);
  }
}
html,
body {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
}
</style>
