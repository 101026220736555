import Vue from 'vue';
import VueRouter from 'vue-router';
import ProductMain from '../components/ProductMain';
import ptmtProd from '../components/ptmtProd';
import ItemDetails from '../components/ItemDetails';
import OtherItems from '../components/otherItems';
import otheritemDetail from '../components/otheritemDetail';
import plumbing_cpvc from '../components/plumbing_cpvc';
import ItemTable_upvc from '../components/itemTable_upvc';
import ItemTable_cpvc from '../components/itemTable_cpvc';
import plumbing_upvc from '../components/plumbing_upvc';
import plumbing_durofit from '../components/plumbing_durofit';
import ItemTable_durofit from '../components/itemTable_durofit';
import plumbing_cemfit from '../components/plumbing_cemfit';
import ItemTable_cemfit from '../components/itemTable_cemfit';
import plumbing_commonfit from '../components/plumbing_commonfit';
import ItemTable_commonfit from '../components/itemTable_commonfit';
import plumbing_ugfit from '../components/plumbing_ugfit';
import ItemTable_ugfit from '../components/itemTable_ugfit';
import plumbing_sonafit from '../components/plumbing_sonafit';
import ItemTable_sonafit from '../components/itemTable_sonafit';
import plumbing_equalfit from '../components/plumbing_equalfit';
import ItemTable_equalfit from '../components/itemTable_equalfit';
import plumbing_pn from '../components/plumbing_pn';
import ItemTable_pn from '../components/itemTable_pn';
import agriculture_column from '../components/agriculture_column';
import agriculture_hdpe from '../components/agriculture_hdpe';
import agriculture_tanks from '../components/agriculture_tanks';
import flexible_suction from '../components/flexible_suction';
import flexible_braided from '../components/flexible_braided';
import flexible_corrugated from '../components/flexible_corrugated';
import flexible_lft from '../components/flexible_lft';
import ItemTable_column from '../components/itemTable_column';
import ItemTable_hdpe from '../components/itemTable_hdpe';
import ItemTable_watertank from '../components/itemTable_watertank';
import documentsDetails from '../components/documentsDetails';
import favitemList from '../components/favitemList';
import cleaningSol from '../components/cleaningSol';
import cleaningSol_details from '../components/cleaningSol_details'




Vue.use(VueRouter);

const routes = [
    {
        name: 'Product',
        path: '/',
        component: ProductMain
    },
    {
        name: 'ptmtProd',
        path: '/ptmtProd/:product',
        component: ptmtProd,
        props: true

    },
    {
        name: 'ItemDetails',
        path: '/ItemDetails/:Pid',
        component: ItemDetails
    },
    {
        name: 'Others',
        path: '/otherItems/:product',
        component: OtherItems,
        props: true
    },
    {
        name: 'plumbing_cpvc',
        path: '/plumbing_cpvc/:product',
        component: plumbing_cpvc,
        props: true
    },
    {
        name: 'plumbing_upvc',
        path: '/plumbing_upvc/:product',
        component: plumbing_upvc,
        props: true

    },
    {
        name: 'plumbing_durofit',
        path: '/plumbing_durofit/:product',
        component: plumbing_durofit,
        props: true

    },
    {
        name: 'plumbing_cemfit',
        path: '/plumbing_cemfit/:product',
        component: plumbing_cemfit,
        props: true

    },
    {
        name: 'plumbing_commonfit',
        path: '/plumbing_commonfit/:product',
        component: plumbing_commonfit,
        props: true

    },
    {
        name: 'plumbing_sonafit',
        path: '/plumbing_sonafit/:product',
        component: plumbing_sonafit,
        props: true

    },
    {
        name: 'plumbing_equalfit',
        path: '/plumbing_equalfit/:product',
        component: plumbing_equalfit,
        props: true

    },
    {
        name: 'plumbing_ugfit',
        path: '/plumbing_ugfit/:product',
        component: plumbing_ugfit,
        props: true

    },
    {
        name: 'plumbing_pn',
        path: '/plumbing_pn/:product',
        component: plumbing_pn,
        props: true

    },
    {
        name: 'otherDetails',
        path: '/otheritemsDetail/:Pid',
        component: otheritemDetail
    },
    {
        name: 'itemtable_upvc',
        path: '/ItemTable_upvc/:Pid',
        component: ItemTable_upvc
    },
    {
        name: 'itemtable_durofit',
        path: '/ItemTable_durofit/:Pid',
        component: ItemTable_durofit
    },
    {
        name: 'itemtable_cemfit',
        path: '/ItemTable_cemfit/:Pid',
        component: ItemTable_cemfit
    },
    {
        name: 'itemtable_commonfit',
        path: '/ItemTable_commonfit/:Pid',
        component: ItemTable_commonfit
    },
    {
        name: 'itemtable_sonafit',
        path: '/ItemTable_sonafit/:Pid',
        component: ItemTable_sonafit
    },
    {
        name: 'itemtable_pn',
        path: '/ItemTable_pn/:Pid',
        component: ItemTable_pn
    },
    {
        name: 'itemtable_equalfit',
        path: '/ItemTable_equalfit/:Pid',
        component: ItemTable_equalfit
    },
    {
        name: 'itemtable_ugfit',
        path: '/ItemTable_ugfit/:Pid',
        component: ItemTable_ugfit
    },
    {
        name: 'itemtable_cpvc',
        path: '/ItemTable_cpvc/:Pid',
        component: ItemTable_cpvc
    },
    {
        name: 'itemtable_column',
        path: '/ItemTable_column/:Pid',
        component: ItemTable_column
    },
    {
        name: 'itemtable_hdpe',
        path: '/ItemTable_hdpe/:Pid',
        component: ItemTable_hdpe
    },
    {
        name: 'itemtable_watertank',
        path: '/ItemTable_watertank/:Pid',
        component: ItemTable_watertank
    },
    {
        name: 'agriculture_column',
        path: '/agriculture_column/:product',
        component: agriculture_column,
        props: true

    },
    {

        name: 'agriculture_hdpe',
        path: '/agriculture_hdpe/:product',
        component: agriculture_hdpe,
        props: true
    },
    {

        name: 'agriculture_tanks',
        path: '/agriculture_tanks/:product',
        component: agriculture_tanks,
        props: true
    },
    {

        name: 'flexible_suction',
        path: '/flexible_suction/',
        component: flexible_suction
    },
    {

        name: 'flexible_braided',
        path: '/flexible_braided/',
        component: flexible_braided
    },
    {

        name: 'flexible_corrugated',
        path: '/flexible_corrugated/',
        component: flexible_corrugated
    },
    {

        name: 'flexible_lft',
        path: '/flexible_lft/',
        component: flexible_lft
    },
    {

        name: 'documents-detail',
        path: '/documents/',
        component: documentsDetails
    },
    {

        name: 'favitemList',
        path: '/favitemList/',
        component: favitemList
    },
    {

        name: 'cleaningSol',
        path: '/cleaningSol/:product',
        component: cleaningSol,
        props: true
    },
    {
        name: 'cleaningSol_details',
        path: '/cleaningSol_details/:Pid',
        component: cleaningSol_details
    },


];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});

export default router;