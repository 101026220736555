<template>
  <v-card class="overflow-hidden" elevation="0">
    <v-app-bar absolute color="indigo" dark shrink-on-scroll prominent src="../assets/banner1.jpg" fade-img-on-scroll
      scroll-target="#scrolling-techniques-3">
      <template v-slot:img="{ props }">
        <v-img v-bind="props" gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"></v-img>
      </template>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-app-bar-title>
        <v-avatar size="200" rounded><v-img src="../assets/appbar.png" height="80"
            width="50"></v-img></v-avatar></v-app-bar-title>
      <v-spacer></v-spacer>

      <v-btn top right fab href="whatsapp://send?phone=+919606491019&text=Hi Raksha Team" class="float" target="_blank"
        data-action="share/whatsapp/share" icon>
        <v-icon size="40" color="white">mdi-whatsapp</v-icon>
      </v-btn>
      <!-- <v-btn icon @click="dial(phone)">
        <v-icon>mdi-phone</v-icon>
      </v-btn> -->

      <!-- <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->

      <template v-slot:extension>
        <v-tabs v-model="tab" grow>
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#sanitary" class="text-h6 font-weight-black"> SANITARY </v-tab>
          <v-tab href="#plumbing" class="text-h6 font-weight-black"> PLUMBING </v-tab>
          <v-tab href="#cleaning" class="text-h6 font-weight-black"> CLEANING </v-tab>
          <v-tab href="#agriculture" class="text-h6 font-weight-black"> AGRICULTURE </v-tab>
          <v-tab href="#watertank" class="text-h6 font-weight-black"> WATER TANK </v-tab>
          <!--<v-tab href="#flexible"> FLEXIBLE </v-tab>-->
        </v-tabs>
      </template>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary class="grey lighten-4">
      <template v-slot:prepend>
        <v-card class="indigo" rounded="0" outlined>
          <v-list-item two-line>
            <v-list-item-avatar size="60" width="80" rounded>
              <img src="../assets/mainlogo.png" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="white--text text-h6">RAKSHA</v-list-item-title>
              <v-list-item-subtitle class="white--text">PIPES & FITTINGS</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </template>
      <v-divider></v-divider>
      <v-list dense>
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
          <v-list-item href="mailto:reports@rakshapipes.com?subject=Mail from Catalog App Viewer">

            <v-list-item-icon>
              <v-icon color="grey--text text--darken-2" class="text-h5">mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="grey--text text--darken-2 text-h6">Email Us</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-4" href="whatsapp://send?phone=+919606491019&text=Hi Raksha Team"
            data-action="share/whatsapp/share">
            <v-list-item-icon>
              <v-icon color="grey--text text--darken-2" class="text-h5">mdi-chat-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="grey--text text--darken-2 text-h6">Chat With Us</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-4" href="https://www.youtube.com/watch?v=3hg11U0GgPA" target="_blank">
            <v-list-item-icon>
              <v-icon color="grey--text text--darken-2" class="text-h5">mdi-youtube-tv</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="grey--text text--darken-2 text-h6">YouTube</v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-4" href="https://www.rakshapipes.com/about.html" target="_blank">
            <v-list-item-icon>
              <v-icon color="grey--text text--darken-2" class="text-h5">mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="grey--text text--darken-2 text-h6">Profile
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-4" to="/documents">
            <v-list-item-icon>
              <v-icon color="grey--text text--darken-2" class="text-h5">mdi-cloud-download</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="grey--text text--darken-2 text-h6">Downloads
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-4" href="https://rewards.rakshapipes.com/" target="_blank">
            <v-list-item-icon>
              <v-icon color="grey--text text--darken-2" class="text-h5">mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="grey--text text--darken-2 text-h6">Rewards
            </v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-4" to="/favitemList">
            <v-list-item-icon>
              <v-icon color="grey--text text--darken-2" class="text-h5">mdi-heart</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="grey--text text--darken-2 text-h6">Favourites
            </v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
      <v-divider></v-divider>
      <template v-slot:append>
        <div class="pa-10">
          <v-btn block outlined class="grey--text"> Release V.0.01 </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-sheet id="scrolling-techniques-3" class="overflow-y-auto blue lighten-5" height="850">

      <v-img src="" min-height="50">
        <v-tabs-items v-model="tab" class="mt-15" grow>
          <v-tab-item value="sanitary">
            <v-container class="pa-15"></v-container>
            <v-container class="mt-10 blue lighten-5">
              <v-row justify-center>
                <v-col cols="12">
                  <v-img src="../assets/san01.png" width="500" class="mx-auto">
                  </v-img>
                  <p class="indigo--text font-weight-black text-h6 text-center">
                    "EXPERT KI SUNO SAHI CHUNO"
                  </p>

                </v-col>
                <!-- <v-col lg="6" md="6">
                  <span class="blue-grey--text font-weight-black text-h5">
                    One Stop
                  </span>
                  <span class="brown--text font-weight-black text-h4">
                    Sanitary </span><br />
                  <span class="indigo--text font-weight-black text-h3">
                    Solution
                  </span>
                </v-col> -->

              </v-row>
            </v-container>
            <v-container class="blue lighten-5">
              <v-row align="center" justify="center">
                <v-card outlined shaped elevation="2" v-for="item in sanitary" :key="item.id" :href="item.component"
                  class="mt-2 mr-2" width="350">
                  <v-img :src="item.src" class="white--text align-end" height="160px">
                  </v-img>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-row>
                      <v-card-title class="font-weight-black text-h6 ml-1 mt-1">
                        <h4 class="font-weight-black">{{ item.title }}</h4>
                      </v-card-title>
                    </v-row>

                    <!-- <v-avatar class="ma-3" size="125" tile>
                    <v-img :src="item.src"></v-img>
                  </v-avatar> -->
                  </div>
                </v-card>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item value="plumbing">
            <v-container class="pa-15"></v-container>
            <v-container class="pa-8 blue lighten-5">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-img src="../assets/plm01.png" width="500" class="mx-auto"></v-img>
                </v-col>
                <span class="blue--text text--darken-4 font-weight-black text-h6">
                  "EXPERT KI SUNO SAHI CHUNO"
                </span>
              </v-row>
            </v-container>
            <v-container class="blue lighten-5">
              <v-row align="center" justify="center">
                <v-card outlined shaped elevation="2" v-for="item in plumbing" :key="item.id" :href="item.component"
                  class="mt-2 mr-2" width="350">
                  <v-img :src="item.src" class="white--text align-end" height="130px">
                  </v-img>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-row>
                      <v-card-title class="font-weight-black text-h6 ml-1 mt-1">
                        <h4 class="font-weight-black">{{ item.title }}</h4>
                      </v-card-title>
                    </v-row>

                    <!-- <v-avatar class="ma-3" size="125" tile>
                    <v-img :src="item.src"></v-img>
                  </v-avatar> -->
                  </div>
                </v-card>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item value="cleaning">
            <v-container class="pa-15"></v-container>
            <v-container class="pa-8 blue lighten-5">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-img src="../assets/cln01.png" width="500"></v-img>
                </v-col>
                <span class="blue--text text--darken-4 font-weight-black text-h6">
                  "EXPERT KI SUNO SAHI CHUNO"
                </span>
              </v-row>
            </v-container>
            <v-container class="blue lighten-5" height="800">
              <v-row align="center" justify="center">
                <v-card outlined shaped elevation="2" v-for="item in cleaning" :key="item.id" :href="item.component"
                  class="mt-2 mr-2" width="350">
                  <v-img :src="item.src" class="white--text align-end" height="130px">
                  </v-img>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-row>
                      <v-card-title class="font-weight-black text-h6 ml-1 mt-1">
                        <h4 class="font-weight-black">{{ item.title }}</h4>
                      </v-card-title>
                    </v-row>

                    <!-- <v-avatar class="ma-3" size="125" tile>
                    <v-img :src="item.src"></v-img>
                  </v-avatar> -->
                  </div>
                </v-card>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item value="agriculture">
            <v-container class="pa-15"></v-container>
            <v-container class="pa-8 blue lighten-5">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-img src="../assets/agr01.png" width="500" class="mx-auto"></v-img>
                </v-col>
                <span class="blue--text text--darken-4 font-weight-black text-h6">
                  "EXPERT KI SUNO SAHI CHUNO"
                </span>
              </v-row>
            </v-container>
            <v-container class="blue lighten-5">
              <v-row align="center" justify="center">
                <v-card outlined shaped elevation="2" v-for="item in agriculture" :key="item.id" :href="item.component"
                  class="mt-2 mr-2" width="350">
                  <v-img :src="item.src" class="white--text align-end" height="130px">
                  </v-img>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-row>
                      <v-card-title class="font-weight-black text-h6 ml-1 mt-1">
                        <h4 class="font-weight-black">{{ item.title }}</h4>
                      </v-card-title>
                    </v-row>

                    <!-- <v-avatar class="ma-3" size="125" tile>
                    <v-img :src="item.src"></v-img>
                  </v-avatar> -->
                  </div>
                </v-card>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item value="watertank">
            <v-container class="pa-15"></v-container>
            <v-container class="pa-8 blue lighten-5">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-img src="../assets/tank01.png" width="500" class="mx-auto"></v-img>
                </v-col>
                <span class="blue--text text--darken-4 font-weight-black text-h6">
                  "EXPERT KI SUNO SAHI CHUNO"
                </span>
              </v-row>
            </v-container>
            <v-container class="blue lighten-5">
              <v-row align="center" justify="center">
                <v-card outlined shaped elevation="2" v-for="item in watertank" :key="item.id" :href="item.component"
                  class="mt-2 mr-2" width="350">
                  <v-img :src="item.src" class="white--text align-end" height="130px">
                  </v-img>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-row>
                      <v-card-title class="font-weight-black text-h6 ml-1 mt-1">
                        <h4 class="font-weight-black">{{ item.title }}</h4>
                      </v-card-title>
                    </v-row>

                    <!-- <v-avatar class="ma-3" size="125" tile>
                    <v-img :src="item.src"></v-img>
                  </v-avatar> -->
                  </div>
                </v-card>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item value="flexible">
            <v-container class="pa-15"></v-container>
            <v-container class="pa-15">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-img src="../assets/flex01.png" width="500" class="mx-auto"></v-img>
                </v-col>
                <span class="blue--text text--darken-4 font-weight-black text-h6">
                  "EXPERT KI SUNO SAHI CHUNO"
                </span>
              </v-row>
            </v-container>
            <v-container>
              <v-row align="center" justify="center">
                <v-card outlined shaped elevation="2" v-for="item in flexible" :key="item.id" :href="item.component"
                  class="mt-2 mr-2" width="350">
                  <v-img :src="item.src" class="white--text align-end" height="130px">
                  </v-img>
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-row>
                      <v-card-title class="font-weight-black text-h6 ml-1 mt-1">
                        <h4 class="font-weight-black">{{ item.title }}</h4>
                      </v-card-title>
                    </v-row>

                    <!-- <v-avatar class="ma-3" size="125" tile>
                    <v-img :src="item.src"></v-img>
                  </v-avatar> -->
                  </div>
                </v-card>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-img>

    </v-sheet>
  </v-card>
</template>

<script>
export default {
  name: "ProductMain",
  data: () => ({
    group: "",
    drawer: false,
    phone: 8022235726,
    tab: null,
    sanitary: [
      {
        id: 1,
        color: "#1F7087",
        src: require("../assets/ptmt.jpg"),
        title: "PTMT TAPS",
        component: "ptmtProd/emd",
      },
      {
        id: 2,
        color: "#952175",
        src: require("../assets/cistern.jpg"),
        title: "CISTERNS",
        component: "OtherItems/cistern",
      },
      {
        id: 3,
        color: "#1F7087",
        src: require("../assets/shower.jpg"),
        title: "SHOWERS",
        component: "OtherItems/showers",
      },
      {
        id: 4,
        color: "#1F7087",
        src: require("../assets/Hfaucets.jpg"),
        title: "FAUCETS",
        component: "OtherItems/faucets",
      },
      {
        id: 5,
        color: "#952175",
        src: require("../assets/sinks.jpg"),
        title: "SINKS",
        component: "OtherItems/sink",
      },
      {
        id: 10,
        color: "#952175",
        src: require("../assets/connec-waste-foot.jpg"),
        title: "CONNECTION WASTE / FOOTVALVE",
        component: "OtherItems/connection",
      },
      {
        id: 11,
        color: "#952175",
        src: require("../assets/bathroomaccess.jpg"),
        title: "BATHROOM ACCESSORIES",
        component: "OtherItems/bathaccess",
      },
      // {
      //   id: 6,
      //   color: "#952175",
      //   src: require("../assets/coupling.png"),
      //   title: "COUPLING & BALLCOCK",
      //   component: "OtherItems/coupling",
      // },
      // {
      //   id: 7,
      //   color: "#952175",
      //   src: require("../assets/jetspray.png"),
      //   title: "JETSPRAY",
      //   component: "OtherItems/jetspray",
      // },
      // {
      //   id: 8,
      //   color: "#952175",
      //   src: require("../assets/dispensers.png"),
      //   title: "DISPENSER",
      //   component: "OtherItems/dispenser",
      // },
      // {
      //   id: 9,
      //   color: "#1F7087",
      //   src: require("../assets/access_main.png"),
      //   title: "ACCESSORIES",
      //   component: "OtherItems/otheraccess",
      // },
    ],
    plumbing: [
      {
        id: 10,
        color: "#1F7087",
        src: require("../assets/cpnw.jpg"),
        title: "CPVC PIPES & FITTINGS",
        component: "plumbing_cpvc/cpvcpipe",
      },

      {
        id: 12,
        color: "#1F7087",
        src: require("../assets/upnw.jpg"),
        title: "UPVC PIPES & FITTINGS",
        component: "plumbing_upvc/upvcpipe",
      },
      {
        id: 18,
        color: "#1F7087",
        src: require("../assets/durofit.jpg"),
        title: "DUROFIT PIPES & FITTINGS",
        component: "plumbing_durofit/durofitpipe",
      },
      {
        id: 19,
        color: "#1F7087",
        src: require("../assets/cemfit.jpg"),
        title: "CEMFIT PIPES & FITTINGS",
        component: "plumbing_cemfit/cemfitpipe",
      },
      {
        id: 20,
        color: "#1F7087",
        src: require("../assets/commonfit.jpg"),
        title: "COMMON SWR FITTINGS",
        component: "plumbing_commonfit/commonfitpipe",
      },
      {
        id: 21,
        color: "#1F7087",
        src: require("../assets/sonafit.jpg"),
        title: "SONAFIT PIPES & FITTINGS",
        component: "plumbing_sonafit/sonafit",
      },
      {
        id: 22,
        color: "#1F7087",
        src: require("../assets/equalfitt.jpg"),
        title: "EQUALFIT PIPES & FITTINGS",
        component: "plumbing_equalfit/equalfit",
      },
      {
        id: 23,
        color: "#1F7087",
        src: require("../assets/underfit.jpg"),
        title: "UNDERGROUND PIPES & FITTINGS",
        component: "plumbing_ugfit/ugfitpipe",
      },
      {
        id: 24,
        color: "#1F7087",
        src: require("../assets/PN16.jpg"),
        title: "PN16 PIPES & FITTINGS",
        component: "plumbing_pn/pn16",
      },
    ],
    agriculture: [
      {
        id: 30,
        color: "#1F7087",
        src: require("../assets/clmpipe.jpg"),
        title: "uPVC COLUMN PIPES",
        component: "agriculture_column/columnpipe",
      },

      {
        id: 31,
        color: "#1F7087",
        src: require("../assets/hdpe.jpg"),
        title: "HDPE PIPES",
        component: "agriculture_hdpe/hdpepipe63",
      },
    ],
    flexible: [
      {
        id: 33,
        color: "#1F7087",
        src: require("../assets/suction.jpg"),
        title: "SUCTION PIPES",
        component: "flexible_suction",
      },

      {
        id: 34,
        color: "#1F7087",
        src: require("../assets/braided.jpg"),
        title: "BRAIDED PIPES",
        component: "flexible_braided",
      },
      {
        id: 35,
        color: "#1F7087",
        src: require("../assets/corrugated.jpg"),
        title: "CORRUGATED PIPE",
        component: "flexible_corrugated",
      },
      {
        id: 36,
        color: "#1F7087",
        src: require("../assets/lft.jpg"),
        title: "LAY FLAT TUBE",
        component: "flexible_lft",
      },
    ],
    watertank: [
      {
        id: 32,
        color: "#1F7087",
        src: require("../assets/tanks.jpg"),
        title: "WATER TANKS",
        component: "agriculture_tanks/watertank",
      },
    ],
    cleaning: [
      {
        id: 40,
        color: "#1F7087",
        src: require("../assets/cleaning.jpg"),
        title: "CLEANING PRODUCTS",
        component: "cleaningSol/cleaning",

      }

    ],
  }),
  methods: {
    dial: function (number) {
      window.location = "tel:" + number;
    },
  },
};
</script>
<style scoped>
html,
body {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 900;
}

.bg {
  background-image: url("../assets/BG5.webp");
  background-repeat: repeat;
}
</style>
